import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { useLocation } from "react-router-dom"
import styled, { createGlobalStyle } from "styled-components"

import useWindowDimensions from "helpers/window-dimensions"
import { handleLiveChat } from "live-chat"

const FakeBeaconButton = styled.button`
  background: ${(props) => props.theme.colors.primary};
  border: none;
  border-radius: 50%;
  bottom: ${(props) => (props.holdCTASpace ? "90px" : "10px")};
  color: white;
  cursor: pointer;
  height: 60px;
  margin: 0;
  padding: 0;
  position: fixed;
  right: 20px;
  user-select: none;
  width: 60px;
  z-index: 999;
  ${(props) => props.theme.mediaQueries.tablet`
    bottom: 40px;
    right: 40px;
  `}
`

const BeaconStyle = createGlobalStyle`
  .BeaconFabButtonFrame {
    bottom: ${(props) => (props.holdCTASpace ? "90px" : "10px")} !important;
    right: 20px !important;
    ${(props) => props.theme.mediaQueries.tablet`
      bottom: 40px !important;
      right: 40px !important;
    `}
  }
`

const FakeBeacon = ({ fixedATC, previewImageExp }) => {
  const { pathname } = useLocation()
  const { isMobile } = useWindowDimensions()

  if (isMobile && pathname.includes("/cart")) {
    return null
  }

  const holdCTASpace =
    isMobile &&
    previewImageExp !== 0 &&
    pathname.includes("/products/") &&
    fixedATC

  return (
    <>
      <BeaconStyle holdCTASpace={holdCTASpace} />
      <FakeBeaconButton
        aria-label="Live Chat"
        holdCTASpace={holdCTASpace}
        onClick={(e) => {
          handleLiveChat()
          e.currentTarget.style.display = "none"
        }}
      >
        <svg height="22" width="24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20.347 20.871l-.003-.05c0 .017.001.034.003.05zm-.243-4.278a2 2 0 0 1 .513-1.455c1.11-1.226 1.383-2.212 1.383-4.74C22 5.782 18.046 2 13.125 2h-2.25C5.954 2 2 5.78 2 10.399c0 4.675 4.01 8.626 8.875 8.626h2.25c.834 0 1.606-.207 3.212-.798a2 2 0 0 1 1.575.083l2.355 1.161-.163-2.878zM10.875 0h2.25C19.13 0 24 4.656 24 10.399c0 2.6-.25 4.257-1.9 6.08l.243 4.279c.072.845-.807 1.471-1.633 1.162l-3.682-1.816c-1.212.446-2.527.921-3.903.921h-2.25C4.869 21.025 0 16.142 0 10.4 0 4.656 4.869 0 10.875 0z"
            fill="#fff"
          ></path>
        </svg>
      </FakeBeaconButton>
    </>
  )
}

FakeBeacon.propTypes = {
  fixedATC: PropTypes.bool,
  previewImageExp: PropTypes.number,
}

const mapStateToProps = (state) => {
  return {
    fixedATC: state.wizardUI.fixedATC,
    previewImageExp: state.experiment.imagePreview.variant_id,
  }
}

export default connect(mapStateToProps)(FakeBeacon)
